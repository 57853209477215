import Container from 'components/layout/Container';
import React from 'react';

interface InfoErrorProps {
  headline: string;
  children?: React.ReactNode;
}

const InfoError: React.FC<InfoErrorProps> = (props) => {
  const { headline, children } = props;

  return (
    <>
      <div className="relative w-full">
        <Container>
          <div className="relative flex min-h-[70vh] flex-col items-center justify-center text-center">
            <div>
              <h1 className="mx-auto max-w-[300px] pb-7 text-3xl uppercase md:max-w-[1026px] md:pb-9 md:text-5xl">
                {headline}
              </h1>
            </div>
            <div>
              <p className="text-sm">{children}</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default InfoError;
