import InfoError from 'components/ui/InfoError';
import Layout from 'containers/Layout';
import contentful from 'lib/contentful';
import { SiteSettingsData } from 'lib/contentful/types';
import { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import NextLink from 'next/link';
import { REVALIDATION_TIME } from '../lib/constants';

const Page: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({ data }) => {
  const { siteSettings } = data;

  return (
    <Layout
      siteSettings={siteSettings}
      metaTags={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
      seo={{
        title: 'Page not found',
        description: 'Sanford page not found',
        openGraphImageUrl: null,
      }}
    >
      <InfoError headline={`Sorry! That page can’t be found`}>
        Please check the URL or{' '}
        <NextLink href={'/'} passHref>
          <a target={'_self'} className="underline">
            click here to go back to the homepage.
          </a>
        </NextLink>
      </InfoError>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps<{
  data: { siteSettings: SiteSettingsData };
}> = async () => {
  const siteSettings = await contentful.siteSettings.getSiteSettings();

  return {
    revalidate: REVALIDATION_TIME.SHORT,
    props: {
      data: { siteSettings },
    },
  };
};

export default Page;
